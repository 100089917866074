import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _258afd1c = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _d8cc186e = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _272d7a98 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _61c9616c = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _03c18d3c = () => interopDefault(import('../pages/administration/bus_stops.vue' /* webpackChunkName: "pages/administration/bus_stops" */))
const _41864f44 = () => interopDefault(import('../pages/administration/buses.vue' /* webpackChunkName: "pages/administration/buses" */))
const _0a76040a = () => interopDefault(import('../pages/administration/drivers.vue' /* webpackChunkName: "pages/administration/drivers" */))
const _0b508ffa = () => interopDefault(import('../pages/administration/roads.vue' /* webpackChunkName: "pages/administration/roads" */))
const _42267e38 = () => interopDefault(import('../pages/administration/users.vue' /* webpackChunkName: "pages/administration/users" */))
const _3e3765be = () => interopDefault(import('../pages/notifications/overview.vue' /* webpackChunkName: "pages/notifications/overview" */))
const _67d25a79 = () => interopDefault(import('../pages/overview/buses.vue' /* webpackChunkName: "pages/overview/buses" */))
const _0413289f = () => interopDefault(import('../pages/overview/daily_report.vue' /* webpackChunkName: "pages/overview/daily_report" */))
const _5793115e = () => interopDefault(import('../pages/overview/passengers.vue' /* webpackChunkName: "pages/overview/passengers" */))
const _5b4f0ba8 = () => interopDefault(import('../pages/overview/reservations.vue' /* webpackChunkName: "pages/overview/reservations" */))
const _0b7a439d = () => interopDefault(import('../pages/system/agents.vue' /* webpackChunkName: "pages/system/agents" */))
const _3765fc47 = () => interopDefault(import('../pages/system/areas.vue' /* webpackChunkName: "pages/system/areas" */))
const _ce22a770 = () => interopDefault(import('../pages/system/availabilities.vue' /* webpackChunkName: "pages/system/availabilities" */))
const _6b60e1ce = () => interopDefault(import('../pages/system/depots.vue' /* webpackChunkName: "pages/system/depots" */))
const _948df84e = () => interopDefault(import('../pages/system/holiday.vue' /* webpackChunkName: "pages/system/holiday" */))
const _ce6743e0 = () => interopDefault(import('../pages/system/operators.vue' /* webpackChunkName: "pages/system/operators" */))
const _0a7fc04e = () => interopDefault(import('../pages/system/prices.vue' /* webpackChunkName: "pages/system/prices" */))
const _220f1e9c = () => interopDefault(import('../pages/system/statistics.vue' /* webpackChunkName: "pages/system/statistics" */))
const _e2081618 = () => interopDefault(import('../pages/systemlog/overview.vue' /* webpackChunkName: "pages/systemlog/overview" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/forget_password/",
    component: _258afd1c,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _d8cc186e,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _272d7a98,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _61c9616c,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/administration/bus_stops/",
    component: _03c18d3c,
    pathToRegexpOptions: {"strict":true},
    name: "administration-bus_stops"
  }, {
    path: "/administration/buses/",
    component: _41864f44,
    pathToRegexpOptions: {"strict":true},
    name: "administration-buses"
  }, {
    path: "/administration/drivers/",
    component: _0a76040a,
    pathToRegexpOptions: {"strict":true},
    name: "administration-drivers"
  }, {
    path: "/administration/roads/",
    component: _0b508ffa,
    pathToRegexpOptions: {"strict":true},
    name: "administration-roads"
  }, {
    path: "/administration/users/",
    component: _42267e38,
    pathToRegexpOptions: {"strict":true},
    name: "administration-users"
  }, {
    path: "/notifications/overview/",
    component: _3e3765be,
    pathToRegexpOptions: {"strict":true},
    name: "notifications-overview"
  }, {
    path: "/overview/buses/",
    component: _67d25a79,
    pathToRegexpOptions: {"strict":true},
    name: "overview-buses"
  }, {
    path: "/overview/daily_report/",
    component: _0413289f,
    pathToRegexpOptions: {"strict":true},
    name: "overview-daily_report"
  }, {
    path: "/overview/passengers/",
    component: _5793115e,
    pathToRegexpOptions: {"strict":true},
    name: "overview-passengers"
  }, {
    path: "/overview/reservations/",
    component: _5b4f0ba8,
    pathToRegexpOptions: {"strict":true},
    name: "overview-reservations"
  }, {
    path: "/system/agents/",
    component: _0b7a439d,
    pathToRegexpOptions: {"strict":true},
    name: "system-agents"
  }, {
    path: "/system/areas/",
    component: _3765fc47,
    pathToRegexpOptions: {"strict":true},
    name: "system-areas"
  }, {
    path: "/system/availabilities/",
    component: _ce22a770,
    pathToRegexpOptions: {"strict":true},
    name: "system-availabilities"
  }, {
    path: "/system/depots/",
    component: _6b60e1ce,
    pathToRegexpOptions: {"strict":true},
    name: "system-depots"
  }, {
    path: "/system/holiday/",
    component: _948df84e,
    pathToRegexpOptions: {"strict":true},
    name: "system-holiday"
  }, {
    path: "/system/operators/",
    component: _ce6743e0,
    pathToRegexpOptions: {"strict":true},
    name: "system-operators"
  }, {
    path: "/system/prices/",
    component: _0a7fc04e,
    pathToRegexpOptions: {"strict":true},
    name: "system-prices"
  }, {
    path: "/system/statistics/",
    component: _220f1e9c,
    pathToRegexpOptions: {"strict":true},
    name: "system-statistics"
  }, {
    path: "/systemlog/overview/",
    component: _e2081618,
    pathToRegexpOptions: {"strict":true},
    name: "systemlog-overview"
  }, {
    path: "/",
    redirect: "/overview/buses/",
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
